<template>
  <div class="limit">
    <div class="title">商店的限时选购</div>
    <div class="cell1">
      <div class="titles">
        <div>促销列表</div>
        <div>
          <el-button
            icon="el-icon-plus"
            @click="handleCreate('add', 0)"
            size="medium"
            type="primary"
            >创建</el-button
          >
        </div>
      </div>
      <div style="font-size: 12px; color: #b0b0b0">
        创建您的独家RM1优惠以加入我门的12.12促销活动！
      </div>
      <div style="margin-top: 20px">
        <el-tabs v-model="activeName" @tab-click="(page = 1), getList()">
          <el-tab-pane label="全部" name="0"></el-tab-pane>
          <el-tab-pane label="进行中的活动" name="1"></el-tab-pane>
          <el-tab-pane label="接下来的活动" name="2"></el-tab-pane>
          <el-tab-pane label="已过期" name="3"></el-tab-pane>
          <div style="display: flex; align-items: center; margin-top: 10px">
            <div style="font-size: 14px; margin-right: 20px">时段</div>
            <el-date-picker
              size="small"
              v-model="time"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              value-format="timestamp"
              @change="timeChange"
            >
            </el-date-picker>
          </div>
          <div class="list">
            <div class="list-head">
              <div style="width: 25%">时段</div>
              <div style="width: 20%">商品</div>
              <div style="width: 20%">商品的点击数/浏览数</div>
              <div style="width: 15%">状态</div>
              <div style="width: 15%">开启/关闭</div>
              <div style="width: 5%">操作</div>
            </div>
            <div v-if="httpsShow">
              <div v-if="listData.list && listData.list.length != 0">
                <div
                  class="lits-content"
                  v-for="item in listData.list"
                  :key="item.id"
                >
                  <div style="width: 25%">{{ item.deal_time }}</div>
                  <div style="width: 20%">
                    <div>
                      为限时选购开启
                      <span stype="color:#ee4d2d">{{
                        item.limit_purchase_open
                      }}</span>
                    </div>
                    <div style="margin-top: 4px; color: #b0b0b0">
                      剩余限额：{{ item.remaining_limit }}
                    </div>
                  </div>
                  <div style="width: 20%">
                    {{ item.goods_views ? item.goods_views : "-" }}
                  </div>
                  <div style="width: 15%">
                    <div class="tag" v-if="item.status_txt == '接下来的活动'">
                      接下来的活动
                    </div>
                    <div class="tag1" v-if="item.status_txt == '进行中的活动'">
                      进行中的活动
                    </div>
                    <div class="tag2" v-if="item.status_txt == '已过期'">
                      已过期
                    </div>
                  </div>
                  <div style="width: 15%">
                    <el-switch
                      @change="handleSwitch($event, item.id)"
                      v-model="item.status"
                      active-color="#13ce66"
                      :active-value="1"
                      :inactive-value="2"
                      :disabled="item.status_txt == '已过期'"
                    >
                    </el-switch>
                  </div>
                  <div style="width: 5%">
                    <div
                      v-if="item.status_txt != '已过期'"
                      class="btn"
                      @click="handleEdit('edit', item.id)"
                    >
                      编辑
                    </div>
                    <div
                      v-else
                      class="btn"
                      @click="handleEdit('info', item.id)"
                    >
                      详情
                    </div>
                    <div class="btn" @click="handleCreate('copy', item.id)">
                      复制
                    </div>
                    <div class="btn" @click="handleEdit('data', item.id)">
                      数据
                    </div>
                    <div
                      @click="handleDel(1, item.id)"
                      v-if="item.status_txt == '进行中的活动'"
                      class="btn"
                    >
                      结束
                    </div>
                    <div
                      @click="handleDel(2, item.id)"
                      v-if="item.status_txt == '接下来的活动'"
                      class="btn"
                    >
                      删除
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                style="text-align: center; color: #b0b0b0; line-height: 100px"
              >
                暂无
              </div>
            </div>
            <div v-else style="text-align: center; padding-top: 20px">
              <img :src="lodingImg" alt="" />
            </div>
          </div>
          <div style="display: flex; justify-content: right; padding: 20px">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="listData.total"
              :page-size="page_size"
              :current-page="page"
              @current-change="change"
            >
            </el-pagination>
          </div>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import {
  limitPurchaseList,
  changeStatus,
  deleteLimitPurchases,
} from "@/api/marketing.js";
export default {
  data() {
    return {
      activeName: "0",
      time: [],
      value: "",
      listData: "",
      page: 1,
      page_size: 10,
      httpsShow: false,
      lodingImg: require("@/assets/img/app/http.gif"),
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.httpsShow = false;
      let time = JSON.stringify(this.time);
      time = JSON.parse(time);
      if (this.time && this.time.length != 0) {
        for (let i = 0; i < time.length; i++) {
          time[i] = time[i] / 1000;
        }
      }
      limitPurchaseList({
        status: this.activeName,
        limit_time: time ? time.join("-") : "",
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        if (res.code == 1) {
          this.httpsShow = true;
          this.listData = res.data;
        }
      });
    },
    handleCreate(type, id) {
      sessionStorage.LIMITTYPE = type;
      sessionStorage.LIMITID = id;

      this.$router.options.routes.forEach((item) => {
        if (item.name == "marketingCenters") {
          item.children.forEach((val) => {
            if (val.name == "createLimit") {
              val.meta.bread[3].name = "创建新的店内限时选购";
            }
          });
        }
      });
      this.$router.push("createLimit");
    },
    handleEdit(type, id) {
      sessionStorage.LIMITTYPE = type;
      sessionStorage.LIMITID = id;
      if (type == "data") {
        this.$router.push("limitData");
      } else {
        this.$router.options.routes.forEach((item) => {
          if (item.name == "marketingCenters") {
            item.children.forEach((val) => {
              if (val.name == "createLimit") {
                val.meta.bread[3].name =
                  type == "edit" ? "编辑限时选购" : "限时选购详情";
              }
            });
          }
        });

        this.$router.push("createLimit");
      }
    },
    handleSwitch(val, id) {
      changeStatus({
        id: id,
        status: val,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success("操作成功");
        }
      });
    },
    timeChange() {
      this.getList();
    },
    handleDel(type, id) {
      let _this = this;
      let text = type == 1 ? "结束" : "删除";
      this.$confirm("确定" + text + "该限时抢购?", text + "限时抢购", {
        confirmButtonText: text,
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteLimitPurchases({
            id: id,
            type: type,
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: text + "成功!",
              });
              if (type == 1) {
                this.activeName = "3";
              }
              _this.getList();
            }
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
    change(page) {
      this.page = page;
      this.getList();
    },
  },
};
</script>
<style lang="less" >
.limit {
  width: 1400px;
  margin: 0 auto;
  padding-top: 20px;
  .title {
    font-size: 20px;
  }
  .cell1 {
    padding: 20px;
    background: #fff;
    margin-top: 20px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    .titles {
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .el-tabs__nav {
      height: 50px;
    }
    .el-tabs__active-bar {
      height: 2px;
    }
    .el-tabs__nav-wrap::after {
      height: 1px;
    }
    .list {
      margin-top: 30px;
      border: 1px solid #eee;
      font-size: 14px;
      .list-head {
        background: #f6f6f6;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .lits-content {
        display: flex;
        align-items: center;
        padding: 10px 0;
        margin: 0 20px;
        justify-content: space-between;
        border-bottom: 1px solid #eee;
        .btn {
          color: #2885e5;
          margin-bottom: 6px;
          cursor: pointer;
        }
        .tag1 {
          background: #eaf9ef;
          color: #55cc77;
          display: initial;
          padding: 2px 4px;
          border-radius: 2px;
        }
        .tag {
          background: #fff1f0;
          color: #ee4d2d;
          display: initial;
          padding: 2px 4px;
          border-radius: 2px;
        }
        .tag2 {
          background: #eeeeee;
          color: #666;
          display: initial;
          padding: 2px 4px;
          border-radius: 2px;
        }
      }
    }
  }
}
</style>